<template>
  <div>
    <apexchart
      :options="chartOptions"
      :series="series"
      type="pie"
      width="100%"
      height="400"
    ></apexchart>
  </div>
</template>

<script>
import ApexCharts from "apexcharts";
import module from "@/core/modules/CrudModule.js";
export default {
  props: {
    statistic: Object,
  },

  components: {
    ApexCharts,
  },

  data() {
    return {
      chartOptions: {},
      series: [
        {
          name: "",
          data: [],
        },
      ],
      chartOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            // style: {
            //   fontSize: "20px",
            //   fontWeight: "bold",
            // },
          },
        },
        labels: [],
        chart: {
          type: "pie",
          categories: [],
        },
        xaxis: {
          categories: [],
        },
        legend: {
          position: "bottom",
          // fontSize: "20px",
        },
        colors: ["#38c762", "#f47b20"],
      },
    };
  },

  methods: {
    async getData(tool) {
      window.arrData = [];
      // let filterParams = `?start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&filter=${this.tooltipPeriod}`;
      let response = await module.get("statistic");
      response = response.items.gender;

      let citizen = [];
      let gender = [];

      //  gender
      for (let b = 0; b < response.length; b++) {
        if (response[b].gender == "male") {
          citizen.push(response[b].amount);
          // gender.push(response[b].gender)
          gender.push("Laki-laki");
        } else if (response[b].gender == "female") {
          citizen.push(response[b].amount);
          // gender.push(response[b].gender)
          gender.push("Perempuan");
        }
      }

      console.log(citizen, "citizen");

      this.series = citizen;
      window.arrData = gender;
      console.log(window.arrData, "window.arrData");
      this.chartLoad = true;

      this.chartOptions = {
        ...this.chartOptions,
        ...{
          labels: window.arrData,
          xaxis: {
            categories: window.arrData,
            title: {
              text: "Persentase Jenis Kelamin",
            },
          },
        },
      };
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
