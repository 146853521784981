<template>
  <!-- <v-app> -->
  <div
    style="
      background: white;
      height: 2500px;
    "
  >
    <!-- Menu::Start -->
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <span class="font-weight-bold font-size">Statistik Penduduk</span>
        </div>
        <div class="col-md-6">
          <Card>
            <template v-slot:body>
              <span class="d-block font-weight-bold font-size my-2"
                >Usia Penduduk</span
              >
              <AgeChart></AgeChart>
            </template>
          </Card>
        </div>
        <div class="col-md-6">
          <Card>
            <template v-slot:body>
              <span class="d-block font-weight-bold font-size my-2"
                >Jenis Kelamin</span
              >
              <GenderChart></GenderChart>
            </template>
          </Card>
        </div>
        <div class="col-md-6">
          <Card>
            <template v-slot:body>
              <span class="d-block font-weight-bold font-size my-2"
                >Pendidikan</span
              >
              <EducationChart></EducationChart>
            </template>
          </Card>
        </div>
        <div class="col-md-6">
          <Card>
            <template v-slot:body>
              <span class="d-block font-weight-bold font-size my-2">Agama</span>
              <ReligionChart></ReligionChart>
            </template>
          </Card>
        </div>
        <div class="col-md-12">
          <Card>
            <template v-slot:body>
              <span class="d-block font-weight-bold font-size my-2"
                >Pekerjaan</span
              >
              <ProfessionChart></ProfessionChart>
            </template>
          </Card>
        </div>
        <div class="col-md-12">
          <span class="font-weight-bold font-size"
            >Statistik Sarana & Prasarana</span
          >
        </div>
        <div class="col-md-6">
          <Card>
            <template v-slot:body>
              <span class="d-block font-weight-bold font-size my-2"
                >Sarana & Prasarana Desa</span
              >
              <InfrastructureChart></InfrastructureChart>
            </template>
          </Card>
        </div>
        <div class="col-md-6">
          <Card>
            <template v-slot:body>
              <span class="d-block font-weight-bold font-size my-2"
                >Rumah Ibadah</span
              >
              <PrayPlaceChart></PrayPlaceChart>
            </template>
          </Card>
        </div>
      </div>
    </div>
  </div>
  <!-- Menu::End -->
  <!-- </div> -->
  <!-- </v-app> -->
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import { setConfiguration } from "@/core/services/jwt.service.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Card from "@/view/content/Card.vue";
import AgeChart from "@/view/components/statistic-website/AgeChart.vue";
import GenderChart from "@/view/components/statistic-website/GenderChart.vue";
import EducationChart from "@/view/components/statistic-website/EducationChart.vue";
import InfrastructureChart from "@/view/components/statistic-website/InfrastructureChart.vue";
import PrayPlaceChart from "@/view/components/statistic-website/PrayPlaceChart.vue";
import ReligionChart from "@/view/components/statistic-website/ReligionChart.vue";
import ProfessionChart from "@/view/components/statistic-website/ProfessionChart.vue";

export default {
  components: {
    Card,
    AgeChart,
    GenderChart,
    EducationChart,
    ReligionChart,
    InfrastructureChart,
    PrayPlaceChart,
    ProfessionChart,
  },
  data() {
    return {
      config: {},
      interval: null,
      time: null,

      modal2: false,
      hamlets: [],
    };
  },
  methods: {
    async getSetting() {
      let response = await module.get("settings-no-auth/1");
      console.log(
        "get setting no auth ============================================================================================"
      );
      if (response != null) {
        this.config = response;
        setConfiguration(JSON.stringify(response));
      }
    },
  },
  mounted() {
    this.getSetting();
    // this.getHamletHeadOption();
  },
  beforeDestroy() {
    // prevent memory leak
    clearInterval(this.interval);
  },
  created() {
    // update the time every second
    this.interval = setInterval(() => {
      // Concise way to format time according to system locale.
      // In my case this returns "3:48:00 am"
      this.time = Intl.DateTimeFormat("en-GB", {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }).format();
    }, 1000);
  },
};
</script>

<style scoped>
.curved-header {
  height: 270px;
  width: 1280px;
  border: none;
  -webkit-box-shadow: inset 0px -1px 0px black;
  box-shadow: inset 0px -1px 0px black;
  margin-bottom: 4px;
  background: white;
  -webkit-box-shadow: inset 0px -28px 50px -21px rgba(156, 127, 156, 1);
  -moz-box-shadow: inset 0px -28px 50px -21px rgba(156, 127, 156, 1);
  box-shadow: inset 0px -28px 50px -21px rgba(156, 127, 156, 1);
}

.curved-footer {
  height: 230px;
  width: 1280px;
  border: none;
  /* -webkit-box-shadow: inset 0px -1px 0px black; */
  box-shadow: inset 0px -1px 0px black;
  margin-bottom: 4px;
  background: white;
  -webkit-box-shadow: inset 0px 28px 50px -21px rgba(156, 127, 156, 1);
  -moz-box-shadow: inset 0px 28px 50px -21px rgba(156, 127, 156, 1);
  box-shadow: inset 0px 28px 50px -21px rgba(156, 127, 156, 1);
}

.sample1 {
  border-radius: 0% 0% 150% 150%/0% 0% 70% 70%;
  width: 100%;
}
.sample2 {
  border-radius: 70% 70% 0% 0%/30% 30% 100% 100%;
  position: absolute;
  bottom: -5px;
  width: 100%;
}

.form-control-lg {
  height: 70px;
}
</style>

<style scoped>
.vue-treeselect--single .vue-treeselect__input-container {
  font-size: inherit;
  height: 70px !important;
}

.vue-treeselect--single .vue-treeselect__input {
  width: 100%;
  height: 70px !important;
}

.font-size {
  font-size: 18px;
  font-weight: bold;
}
</style>
